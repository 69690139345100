import { PropsWithChildren } from "react";
import LegacyLayout from "./legacy-layout";

export default function GuestLayout({ children }: PropsWithChildren) {
    return (
        <LegacyLayout>
            <div className="flex flex-col  mt-10 gap-2 sm:justify-center items-center pt-6 sm:pt-0">
                <img src="@/../images/onyx_mobi.png" alt="Onyx Logo" />
                {children}
            </div>
        </LegacyLayout>
    );
}
